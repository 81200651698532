.video-converting{
    .entity-name {
        max-width: 100px;
    }

    &.card.video-status {
        .dropdown-toggle{
            padding: 0.25rem 0.8rem;
        }
        .dropdown-toggle:after{
            display: none;
        }
    }
}

.video-converting-raw-status{
    white-space: pre;
    font-size: 0.8em;
    width: 100%;
    height: 100%;
    overflow: auto;
    background: #f5f5f5;
    padding: 10px;
}
