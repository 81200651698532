/* Background color by entity type */
@each $key, $color in $entity-type-colors {
    .entity-bg--#{$key} {
        background: $color !important;
    }

    .entity-color--#{$key} {
        color: $color !important;
    }
}

.bg-removed {
    background-color: rgba($danger, .1) !important;
}

.bg-updated {
    background-color: rgba($primary, .1) !important;
}

.bg-created {
    background-color: rgba($success, .1) !important;
}

.justify-self-end{
    margin: auto 0 auto auto;
}

.small-text {
    font-size: smaller;
    line-height: 0.9em;
}

.w-0 {
    width: 0 !important;
}

.mw-50 {
    max-width: 50% !important;
}

.btn-disabled{
    pointer-events: none;
}

.relative-top{
    position: relative;
    z-index: 99;
}
