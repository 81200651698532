.entity-image-editor__images {
    margin: 0 -15px;
    padding: 0;
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;

    li {
        flex-basis: 25%;
        padding: 15px;

        &:hover {
            background: $gray-100;
        }

        img {
            max-width: 100%;
        }
    }
}

.entity-image-editor__images__image {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    a {
        position: relative;
    }
}

.entity-image-editor__images__image--active a {
    &::before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba($green, .6);
    }
}
