.media-uploader {
    background: $gray-100;
    padding: .5rem;
}

.media-uploader--dragging {
    .media-uploader__inner {
        border-color: $primary;
        background: rgba($primary, .3);
    }
}

.media-uploader__inner {
    position: relative;
    overflow: hidden;
    border: .25rem dashed $gray-400;
    background: $white;
    text-align: center;
    padding: 2rem;
    color: $gray-600;

    i {
        font-size: 6rem;
        line-height: 6rem;
    }

    h1 {
        margin: 1rem 0 0 0;
    }
}

.media-uploader__button__wrapper {
    margin-top: 1rem;
}

.media-uploader__checkbox__wrapper {
    padding-top: 15px;

    input {
        margin-right: 5px;
    }

    label {
        margin-right: 10px;
    }
}

.media-uploader__input {
    width: 100%;
    height: 100%;
    position: absolute;
    left: -1000em;
    top: -1000em;
}
