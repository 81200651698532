.pager {
    display: flex;
    margin-bottom: 1rem;
    justify-content: center;
    flex-wrap: wrap;
}

.pager__item {
    flex-grow: 0;
    margin: 1rem .5rem;

    span {
        cursor: pointer;
        display: block;
        padding: .25rem .5rem;
        background: $gray-200;

        &:hover {
            background: $primary;
            color: $white;
        }
    }
}

.pager__item--active {
    span {
        background: $primary;
        color: $white;
    }
}
