// Bootstrap config
$form-group-margin-bottom: .5rem;
$label-margin-bottom: .25rem;
$input-height: 2rem;
$input-padding-y: .125rem;
$input-font-size: .875rem;
$table-cell-padding: .375rem;
$btn-font-size: .875rem;

$entity-type-colors: (
    "award": #f18817,
    "band": #4caf50,
    "person": #ffc107,
    "venue": #ff5722,
    "event": #2196f3,
    "eventseries": #2aa2ce,
    "publication": #e91e63,
    "reference": #55c725,
    "publicationitem": #f34982,
    "musicalperiod": #647cff,
    "document": #0fcab7,
    "image": #d04ae6,
    "audio": #607d8b,
    "video": #0787d1,
    "album": #aee63f,
    "song": #ffc107,
    "tag": #42d490,
    "institution": #b7d661,
    "default": #f3f3f3,
)
