/**
 * https://getbootstrap.com/docs/4.1/examples/dashboard/
 */
.navbar {
    //position: relative;
}

.navbar-toggle-sidebar {
    position: absolute;
    right: 15px;
    top: 10px;
    padding: 0 15px;

    @include media-breakpoint-up(sm) {
        display: none;
    }
}

.sidebar {
    display: none;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 100; /* Behind the navbar */
    padding: 48px 0 0; /* Height of navbar */
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);

    @include media-breakpoint-up(sm) {
        display: block;
    }

    &.sidebar--visible {
        display: block;
    }
}

.sidebar-sticky {
    position: relative;
    top: 0;
    height: calc(100vh - 48px);
    padding-top: .5rem;
    overflow-x: hidden;
    overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
    .sidebar-sticky {
        position: -webkit-sticky;
        position: sticky;
    }
}

.sidebar .nav-link {
    font-weight: 500;
    color: #333;
}

.sidebar .nav-link .feather {
    margin-right: 4px;
    color: #999;
}

.sidebar .nav-link.active {
    color: #007bff;
}

.sidebar .nav-link:hover .feather,
.sidebar .nav-link.active .feather {
    color: inherit;
}

.sidebar .nav-item a {
    i {
        margin-right: 5px;
    }

    span {
        position: relative;

        &::after {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            bottom: 0;
            width: 0;
            height: 1px;
            transition: .3s;
        }
    }

    &:hover {
        span::after {
            width: 100%;
        }
    }
}

@each $name, $color in $entity-type-colors {
    .sidebar .nav-item--#{$name} a {
        span::after {
            background: #{$color};
        }
    }
}

.sidebar-heading {
    font-size: .75rem;
    text-transform: uppercase;
}

[role="main"] {
    padding-top: 48px; /* Space for fixed navbar */
}

.navbar-brand {
    padding-top: .75rem;
    padding-bottom: .75rem;
    font-size: 1rem;
    background-color: rgba(0, 0, 0, .25);
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, .25);
}
