.entity-reference {
    .small-caps{
        font-variant: small-caps;
    }

    .italic{
        font-style: italic;
    }

    .text-trancate{
        max-width: 33%;
    }
}
