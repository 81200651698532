.spinner{
    &.spinner-medium{
        min-height: 25vh;
        display: flex;

        > *{
            margin: auto;
        }
    }

    &.spinner-large{
        min-height: 50vh;
        display: flex;

        > *{
            margin: auto;
        }
    }
}
