.search-dropdown {
    position: relative;
    width: 100%;
    margin-bottom: 1rem;
}

.search-dropdown__input {
    width: 100%;
    height: $input-height;
    border: $input-border-width solid $input-border-color;
    border-radius: $input-border-radius;
    padding: $input-padding-y $input-padding-x;
}

.search-dropdown--result input {
    border-bottom: none;
    border-radius: $input-border-radius $input-border-radius 0 0;
}

.search-dropdown__input-wrapper{
    position: relative;

    &::after {
        position: absolute;
        width: auto;
        bottom: 0;
        left: 0;
        border-bottom: 1px solid $input-border-color;
        content: '';
        margin: 0 0.5rem;
        right: 0;
    }
}

.search-dropdown__dropdown {
    position: absolute;
    width: 100%;
    height: 10rem;
    top: $input-height;
    left: 0;
    overflow: auto;
    background: $white;
    z-index: 10;
    border: $input-border-width solid $input-border-color;
    border-top: none;
    border-radius: 0 0 $input-border-radius $input-border-radius;
}

.search-dropdown__dropdown__loader {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .3);
    z-index: 2;
}

.search-dropdown__icon {
    position: absolute;
    right: 0.6em;
    top: 0.2em;
    background: white;
}

.search-dropdown__list {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.search-dropdown__list__item {
    padding: .5rem 1rem;
    line-height: 1.5rem;

    &.search-dropdown__list__item--active,
    &:hover {
        background: $gray-200;
        cursor: pointer;
    }
}

.search-dropdown__help {
    font-size: .875rem;
    color: $gray-700;
    text-align: right;
    margin-bottom: 1rem;
}
