.image-list {
    margin-top: 30px;

    ul {
        display: flex;
        flex-wrap: wrap;
        list-style-type: none;
        margin: 0;
        padding: 0;
    }

    li {
        transition: .6s;
        padding: 15px;
        max-width: 300px;
        cursor: pointer;
        background: $gray-100;

        a {
            display: block;
        }

        &:nth-child(2n) {
            background: $white;
        }

        &:hover {
            background: $gray-400;
        }
    }

    img {
        max-width: 100%;
    }
}
