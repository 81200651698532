.media-gallery-browser {
    ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
        display: flex;
        flex-wrap: wrap;
    }

    li {
        padding: 10px;
        cursor: pointer;
        text-align: center;

        &:hover {
            background: $gray-300;
        }

        input {
            margin-top: 5px;
        }
    }
}

.media-gallery-browser__toggle-all {
    input {
        margin-right: 5px;
    }
}
