.popup__overlay {
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba($black, .3);
}

.popup__content {
    position: relative;
    width: 100%;
    height: 100%;
    background: $white;
    display: flex;
    flex-direction: column;
    padding-bottom: 3rem;
}

.popup__content__header {
    position: relative;
    min-height: 2.5rem;
    flex-shrink: 0;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    padding: 0 1rem;
}

.popup__content__close {
    cursor: pointer;
    font-size: 3rem;
}

.popup__content__body {
    flex-grow: 1;
    padding: 1rem 2rem 3rem 2rem;
}

.popup__content__buttons {
    text-align: right;
    padding: 1rem;
}

.popup__content__sticky {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
}

@include media-breakpoint-up(md) {
    .popup__content {
        width: 40rem;
        height: 40rem;
        max-height: 80%;
    }

    .popup__content__body {
        overflow-y: auto;
    }
}

@include media-breakpoint-up(lg) {
    .popup__content {
        width: 80rem;
        height: 80rem;
    }
}
