.table {
    th,
    td {
        font-size: .875rem;
    }

    th a {
        color: $black;
    }

    thead th {
        vertical-align: middle;
    }

    tr.table-light.deleted{
        opacity: .5;
    }
}
