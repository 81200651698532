.connection-manager {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    border: .0625rem solid $gray-400;
    padding: 1rem 1rem 4rem 1rem;
    background: $gray-100;
}

.connection-manager__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    user-select: none;
    width: 13rem;
    height: 5rem;
    padding: .25rem;
    margin: 1rem;
    background: $white;
    text-align: center;
    cursor: move !important;
    font-size: .875rem;
    overflow: hidden;

    &.sortable-chosen {
        background: transparent !important;
        border: 4px dotted $gray-400;
    }

    p {
        margin-bottom: .25rem;
    }
}

.connection-manager__sticky {
    position: fixed;
    z-index: 100;
    bottom: 0;
    right: 0;
    width: 100%;
    padding: 1rem;
}

.connection-manager__sticky__content {
    background: rgba($warning, .6);
    width: 100%;
    padding: 2rem .5rem 1rem .5rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
