.image-types {
    margin-top: 30px;

    ul {
        display: flex;
        list-style-type: none;
        margin: 0 -15px;
        padding: 0;
    }

    li {
        transition: .6s;
        padding: 15px;
        width: 300px;
        cursor: pointer;

        &:hover {
            background: $gray-300;
        }
    }

    img {
        width: 100%;
    }

    h2 {
        font-size: 1rem;
        margin: 0 0 15px 0;
    }
}
