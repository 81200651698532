.form-group__time {
    display: flex;

    input {
        max-width: 5rem;
    }

    input + input {
        margin-left: .25rem;
    }
}
