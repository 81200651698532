.order-arrows {
    display: flex;
    flex-direction: column;
    padding-left: 1rem;

    i {
        line-height: 1rem;
        font-size: 1rem;
        color: $gray-400 !important;
    }

    .order-arrows__icon--active {
        color: $black !important;
    }
}
