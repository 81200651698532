.entity-images-status {
    ul {
        display: flex;
        margin: 0;
        padding: 0;

        li {
            list-style-type: none;
            margin: 0;
            padding: 0 5px;
            color: $gray-400;
        }

        .active {
            color: $black;
        }
    }
}
