.entity-images {
    margin: 0 -15px;
    padding: 0;
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;

    .card{
        margin: 0 1rem 1rem;
        flex: 0 0 200px;

        .card-img-top{
            height: 200px;
            overflow: hidden;
            width: 100%;
            display: flex;
            justify-content: center;
            align-content: center;
            background: #dbdbdb;

            > *{
                margin: auto;
                max-width: 100%;
                max-height: 100%;
            }
        }
    }
}
